export enum UserTypeEnum {
    TRAVEL_AGENT = "travelAgent",
    STAFF = "staff",
    RETAIL = "retail",
  }
  


export type TUserPendingFormValues = {
    userType: UserTypeEnum;
    email: string;
    firstName: string;
    lastName?: string;
    phone?: string;
    companyName: string;
    panNumber?: string;
    gst?: string;
  };